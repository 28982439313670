import React, { useEffect, useState, } from "react";
// import { Link, } from 'react-router-dom';
import { FormattedMessage, useIntl, } from "react-intl";
import { useForm, } from "react-hook-form";
import { useLocalStorage } from 'usehooks-ts';
import { Image, notification, } from "antd";

import { Info, UsersThree, XCircle, } from '@phosphor-icons/react';
import { LoadingOutlined, } from '@ant-design/icons';

import { GetProductAvailability, } from '../../../services/products';
import { GetBookingStartAmount } from '../../../services/public';
import { FormatEuro, calculateTotal, getRentalPeriod, } from './../../../components/helpers';
import SloopSelectorInfoModal from '../../../components/modals/sloop.selector.info.modal';
import SloopSelectorTimeModal from '../../../components/modals/sloop.selector.time.modal';

import PlaceHolder from '../../../assets/images/placeholder.png';


const ProductCardView = ({
    data,
    rentalDate,
    history,
    categories,
    ...props
}) => {

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openTimeModal, setOpenTimeModal] = useState(false);
    const [selectedTime, setSelectedTime] = useState(undefined);
    const [maxSelectableSloop, setMaxSelectableSloop] = useState(data.product_stock_amount);
    const [formIsValid, setFormIsValid] = useState(false);
    const [visible, setVisible] = useState(false);          // used for the image preview
    const [availableCheckLoading, setAvailableCheckLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
    const [bookHalfHour, setBookHalfHour] = useState(false);

    const { formState, watch, register, reset, setValue, } = useForm({ mode: "all" });
    const { triggerShoppingCart, themeData, language, GAevent, } = props;
    const { branding_location, branding_financial, } = themeData;
    const interval_amount = watch("interval_amount");
    const product_amount = watch("product_amount");
    const trip_type = watch("trip_type");

    // const random_id = Math.random().toString(36).substring(2, 15);
    const [shoppingCart, setShoppingCart] = useLocalStorage('shoppingcart_sloep', []);
    const [triptime, setTriptime] = useState(undefined);      // the sail time rental period
    const [timeSlotTrip, setTimeSlotTrip] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        reset({
            product_amount: 1,
            interval_amount: 3,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // getting the order amount to find out if we needs to start whole of half hours selection
    useEffect(() => {
        const post_data = {
            product_id: data.product_id,
            date_range: [rentalDate, rentalDate],
        };
        GetBookingStartAmount(post_data).then(response => {
            setBookHalfHour(response.data.book_half_hour);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalDate]);

    useEffect(() => {
        if (trip_type) {
            const tmp_trip = data.product_pricing.product_time_slots.find(p => p.ts_id === trip_type);
            setSelectedTime(tmp_trip?.ts_interval_start_time)
            setValue('interval_amount', tmp_trip.ts_interval_amount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip_type]);

    useEffect(() => {
        // set the vaartijden (trip time) get this from the product_custom_fields
        const tmp_triptime = data.product_custom_fields.find(p => p.field_placeholder === "vaartijden");
        if (tmp_triptime) {
            if (tmp_triptime.field_value === 'TIMESLOT') {
                setTimeSlotTrip(true);
            } else {
                setTriptime(tmp_triptime.field_value.split(',').map(Number));
            }
        }

        const tmp_max_sloop = data.product_custom_fields.find(p => p.field_placeholder === "max_aantal_sloepen_keus");
        setMaxSelectableSloop(tmp_max_sloop?.field_value || data.product_stock_amount);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // select the current pricing / time slot from the product pricing.
    useEffect(() => {
        if (rentalDate) {
            const period = [rentalDate, rentalDate];
            var current_time_slot;

            if (trip_type) {
                current_time_slot = data.product_pricing.product_time_slots.find(p => p.ts_id === trip_type);
            } else {
                current_time_slot = getRentalPeriod(period, data.product_pricing.product_time_slots);
            }
            setSelectedTimeSlot(current_time_slot || {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalDate, trip_type]);

    // Check if the form status is valid (all fields has been set)
    useEffect(() => {
        if (formState.isValid && selectedTime && rentalDate) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.isValid, selectedTime, rentalDate]);

    // make sure the user can not select a trip time that is longer then the selected time slot
    useEffect(() => {
        if (Object.keys(selectedTimeSlot).length > 0) {
            // Make sure the customer can not selected a start time longer then the time periode in the timeslot
            const maxEndHour = parseInt(selectedTimeSlot?.ts_interval_end_time.split(':')[0]) - interval_amount;

            if (selectedTime) {
                const selectedEndHour = parseInt(selectedTime?.split(':')[0]);
                if (selectedEndHour > maxEndHour) {
                    console.warn("Selected timeslot and number of hours are not matching");
                    setSelectedTime(undefined);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval_amount, selectedTimeSlot]);

    // (B) getting the selected category
    useEffect(() => {
        if (categories?.length > 0 && data) {
            const cat = categories.find((item) => { return item.cat_id === data?.product_category });
            setSelectedCategory(cat);
        }

        // eslint-disable-next-line
    }, [categories, data]);

    const selectProduct = () => {
        // setAvailableChecked(false);
        setAvailableCheckLoading(true);

        const post_data = {
            date_range: [rentalDate, rentalDate],
            start_time: selectedTime,
            product_id: data.product_id,
            product_amount: product_amount,
            interval_amount: interval_amount,
            interval_type: "HOUR",
        };

        // setup shoppingcart item data
        const daysFreeOfCharge = branding_location?.location_hours.filter(day => !day.enable && !day.charge_when_closed);
        const shoppingcart_item = {
            product_data: data,
            product_category: selectedCategory,
            bookdetail_amount: product_amount,
            interval_amount: interval_amount,
            date_range: [rentalDate, rentalDate],
            start_time: selectedTime,
            days_free_of_charge: daysFreeOfCharge,
            minimal_24hours_order: branding_financial.minimal_24hours_order,
            unique_id: Math.random().toString(36).substring(2, 15),
            interval_type: "HOUR",
            ts_id: trip_type,
        };

        // if the requested amount is large then the stock amount, block
        GetProductAvailability(post_data, history).then(response => {
            if (response.status) {
                // const products_available = data.product_stock_amount - response.data.reserved;
                const products_available = response.data.product_stock_amount - response.data.reserved;

                if (products_available >= post_data.product_amount) {
                    updateShoppingCart(shoppingcart_item);

                    GAevent('CTA', 'Click', 'Sloep toegevoegd aan winkelwagen', 'Sloep toegevoegd aan winkelwagen');
                    setShoppingCart(shoppingCart);
                    triggerShoppingCart();
                    history("/arrangementen");
                    window.scrollTo({ top: 0 });
                } else {
                    notification['error']({
                        message: intl.formatMessage({ id: "not-available" }),
                        description: intl.formatMessage({ id: "not-available-info" }),
                    });
                }
                setAvailableCheckLoading(false);

            } else {
                notification['error']({
                    message: intl.formatMessage({ id: "not-available" }),
                    description: intl.formatMessage({ id: "not-available-info" }),
                });
                setAvailableCheckLoading(false);
            }
        })
    };

    const updateShoppingCart = (data) => {
        const new_product_id = data.product_data.product_id;

        // overwrite the existing item in the shoppingcart
        // Otherwise it gives issues on the availability check
        for (let index = 0; index < shoppingCart.length; index++) {
            const element = shoppingCart[index];
            if (element.product_data.product_id === new_product_id) {
                shoppingCart.splice(index, 1);
            }
        };
        shoppingCart.push(data);
    };

    const calculatePrice = (time) => {
        const tmp_data = {
            product_data: data,
            days_free_of_charge: undefined,
            selected_options: [],
            minimal_24hours_order: false,
            bookdetail_amount: product_amount,
            interval_amount: time,
        };

        if (rentalDate) {
            // set the choosen date, to get the correct pricing.
            tmp_data.date_range = [rentalDate, rentalDate];
            const price = calculateTotal(tmp_data); //data.product_pricing.product_time_slots.find(p => p.ts_interval === time);

            return price.total_price;
        } else {
            // const price = getLowestPrice(data, []);
            return 0;
        }
    };

    const calculateTripPrice = (trip) => {
        return trip.ts_interval_amount * trip.ts_interval_pricing[0].interval_price;
    };

    const renderHTMLContent = (htmlContent) =>
        React.createElement('div', {
            dangerouslySetInnerHTML: { __html: htmlContent },
        });

    return (
        <React.Fragment>
            {Object.keys(selectedTimeSlot).length === 0 ? (
                null
            ) : (
                <div className="col-left flex-2 calc-gap-s tablet-width-full boat-available-item">
                    <div className={`card-wrapper ${availableCheckLoading && ' add-overlay'}`}>

                        <p className="spinner">
                            {availableCheckLoading && (<LoadingOutlined />)}
                        </p>

                        {/* Product image */}
                        <div className="card-img">
                            <Image.PreviewGroup
                                preview={{
                                    visible: visible,
                                    // current: 4,
                                    onVisibleChange: (value) => {
                                        setVisible(value);
                                    },
                                }}>
                                <Image src={data.product_images[0]?.image_s3_key ? `https://${process.env.REACT_APP_AWS_DATASTORE}/${data.product_images[0].image_s3_key}` : PlaceHolder}
                                    // style={{ display: "inline" }}
                                    rootClassName="product-cover-image"
                                    className="radius-top-l _smalll"
                                />
                                {data.product_images.map((item, index) => (
                                    <React.Fragment key={item.image_id}>
                                        {index > 0 && (
                                            <Image
                                                rootClassName="hide"
                                                className=""
                                                src={`https://${process.env.REACT_APP_AWS_DATASTORE}/${item.image_s3_key}`}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </Image.PreviewGroup>
                        </div>

                        {/* Product details */}
                        <div className="card-content bg-white padding-m">

                            {/* Product name */}
                            <h4 className="content-title size-h3 weight-semibold default-color">
                                {data?.product_names?.[`product_name_${language}`] || data?.product_names?.product_name_nl}
                            </h4>

                            {/* Product description */}
                            <div className="content-description">
                                {renderHTMLContent(data?.product_descriptions?.[`product_description_${language}`] || data?.product_descriptions?.product_description_nl)}
                            </div>

                            {/* People amount product_custom_fields */}
                            {data.product_custom_fields.find(p => p.field_placeholder === "aantal_personen")?.field_value > 0 && (
                                <span className="inline-block bg-grey primary-color radius-m"
                                    style={{ padding: "6px 15px" }}>
                                    <i className="fas">
                                        <UsersThree size={20} className="icon" />
                                    </i>
                                    <FormattedMessage
                                        id="max-capacity-persons"
                                        values={{ amount: data.product_custom_fields.find(p => p.field_placeholder === "aantal_personen")?.field_value || 0 }} />
                                </span>
                            )}

                            {/* Info about boat amount (open modal)  */}
                            <p
                                onClick={e => setOpenInfoModal(true)}
                                className="content-description weight-semibold margin-b-m display-flex flex-between flex-vertical-center cursor-pointer maak-info-btn">
                                <FormattedMessage id="make-choice" />
                                <span className="flex-5p">
                                    <Info size={20} className="bg-secondary white-color rounded-icon" />
                                </span>

                                {/* <Info size={20} className="icon rounded-icon xsmall bg-darkgrey white-color hover-bg-primary open-popup_btn" /> */}
                            </p>

                            {/* Product amount selector */}
                            <select
                                {...register("product_amount",
                                    { required: { value: true, message: "inputfield-FieldIsRequired" } })}
                                className="input-form width-100 weight-regular default-color margin-b-l">
                                <option value="">
                                    <FormattedMessage id="choose-boat-amount" />
                                </option>
                                {Array.from({ length: Math.min(data.product_stock_amount, maxSelectableSloop) }, (_, index) => (
                                    <option key={index} value={index + 1}>
                                        {index + 1 === 1 ?
                                            <>1 <FormattedMessage id={`sloop`} /></> :
                                            <>{index + 1} <FormattedMessage id={`sloops`} /></>}
                                    </option>
                                ))}
                            </select>

                            {/* Timeslot pricing container */}
                            {timeSlotTrip ? (
                                <div className="card-content__options display-flex gap-s margin-b-l flex-center-center">
                                    {data.product_pricing.product_time_slots?.map((trip, index) => (
                                        <React.Fragment key={index}>
                                            {trip.ts_enabled && (
                                                <div className="flex-2 calc-gap-xs phone-m-width-ful">
                                                    <label
                                                        onClick={e => setValue('trip_type', trip.ts_id)}
                                                        className={`${watch('trip_type') === trip.ts_id && 'selected-time'} bg-grey radius-m text-align-center padding-xs display-block`}>
                                                        {trip.ts_name} <FormatEuro amount={calculateTripPrice(trip)} />
                                                        {/* <FormatEuro amount={trip.ts_interval_pricing[0].interval_price} /> */}
                                                    </label>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            ) : (
                                <div className="card-content__options display-flex gap-s margin-b-l flex-center-center">
                                    {!triptime && <span><FormattedMessage id="no-triptime-available" /></span>}

                                    {triptime?.map((time, index) => (
                                        <div
                                            key={index}
                                            className="flex-2 calc-gap-xs phone-m-width-ful">
                                            <label
                                                onClick={e => setValue('interval_amount', time)}
                                                className={`${watch('interval_amount') === time && 'selected-time'} bg-grey radius-m text-align-center padding-xs display-block cursor-pointer`}>
                                                {time} <FormattedMessage id="hour" /> <FormatEuro amount={calculatePrice(time)} />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Select timeslot */}
                            {timeSlotTrip ? (
                                <div className="card-content__options_2 margin-b-l">
                                    <div
                                        //   onClick={e => { setOpenTimeModal(true) }}
                                        className="input-form width-100 weight-regular default-color margin-b-l"
                                        style={{ cursor: 'not-allowed', display: 'flex', alignItems: 'center' }}>
                                        {data.product_pricing.product_time_slots.find(p => p.ts_id === watch('trip_type'))?.ts_interval_start_time || <FormattedMessage id="choose-time-of-day" />}
                                        {/* {selectedTime} watch('trip_type') */}
                                    </div>
                                </div>
                            ) : (
                                <div className="card-content__options_2 margin-b-l">
                                    <div
                                        onClick={e => { setOpenTimeModal(true) }}
                                        className="input-form width-100 weight-regular default-color margin-b-l pointer"
                                        style={{ display: 'flex', alignItems: 'center' }}>
                                        {selectedTime ? selectedTime : <FormattedMessage id="choose-time-of-day" />}
                                    </div>
                                </div>
                            )}

                            {/* Select button */}
                            <div className="card-content__cta">

                                <button type="button"
                                    disabled={!formIsValid}
                                    onClick={e => { selectProduct() }}
                                    className={`slv-button bg-secondary button-style weight-semibold white-color width-100 step__cta-nav-btn next-nav-btn ${!formIsValid && 'disable-bg'}`}
                                >
                                    <FormattedMessage id="select-sail-time" />
                                </button>
                            </div>

                            {formIsValid ? (
                                <p className="form-valid-list">
                                    {/* Geen fouten */}
                                </p>
                            ) : (
                                <div className="form-error-list">
                                    <ul className="content-listing horizontal">

                                        {!rentalDate && (
                                            <li className="display-flex flex-vertical-center gap-s mt-1">
                                                <p className="content-description">
                                                    <XCircle size={20} className="flex-5p" />
                                                    <FormattedMessage id="no-booking-date" />
                                                </p>
                                            </li>
                                        )}

                                        {formState.errors?.product_amount && (
                                            <li className="display-flex flex-vertical-center gap-s mt-1">
                                                <p className="content-description">
                                                    <XCircle size={18} className="flex-5p icon" />
                                                    <FormattedMessage id="no-boat-amount" />
                                                </p>
                                            </li>
                                        )}

                                        {!interval_amount && (
                                            <li className="display-flex flex-vertical-center gap-s mt-1">
                                                <p className="content-description">
                                                    <XCircle size={18} className="flex-5p icon" />
                                                    <FormattedMessage id="no-sail-hours" />
                                                </p>
                                            </li>
                                        )}

                                        {!selectedTime && (
                                            <li className="display-flex flex-vertical-center gap-s mt-1">
                                                <p className="content-description">
                                                    <XCircle size={18} className="flex-5p icon" />
                                                    <FormattedMessage id="no-time-of-day" />
                                                </p>
                                            </li>
                                        )}

                                    </ul>
                                </div>
                            )}

                        </div>
                    </div>

                    {openInfoModal && (
                        <SloopSelectorInfoModal
                            modalIsOpen={openInfoModal}
                            modalIsOpenHandler={setOpenInfoModal}
                        />
                    )}

                    {openTimeModal && (
                        <SloopSelectorTimeModal
                            modalIsOpen={openTimeModal}
                            modalIsOpenHandler={setOpenTimeModal}
                            selectedTime={selectedTime}
                            setSelectedTime={setSelectedTime}
                            selectedTimeSlot={selectedTimeSlot}
                            numberOfHour={interval_amount || 1}
                            display30Minutes={bookHalfHour}
                        />
                    )}

                </div>
            )}
        </React.Fragment>

    );
};

export default ProductCardView;