import React, { useEffect, useState, } from "react";
import { Modal, Alert, } from 'antd';
import { FormattedMessage, } from "react-intl";
import { XCircle, } from "@phosphor-icons/react";

const SloopSelectorTimeModal = ({
    modalIsOpen,
    modalIsOpenHandler,
    selectedTime,
    setSelectedTime,
    selectedTimeSlot,
    numberOfHour = 0,   // the amount of hours the user wants to rent the boat
    display30Minutes,
    ...props
}) => {

    const [times, setTimes] = useState([]);

    useEffect(() => {
        if (selectedTimeSlot) {
            generateTimes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeSlot]);

    // Generate time slots. Take in account the number of hours the user wants to rent the boat
    const generateTimes = () => {
        const result = [];
        const startHour = parseInt(selectedTimeSlot.ts_interval_start_time?.split(':')[0]);
        const endHour = parseInt(selectedTimeSlot.ts_interval_end_time?.split(':')[0]) - (parseInt(numberOfHour));

        for (let i = startHour; i <= endHour; i += 1) { //selectedTimeSlot.ts_interval_amount) {
            const time = formatTime(i, display30Minutes);
            result.push(time);
        }
        setTimes(result);
    };

    const formatTime = (hour, isHalfHour) => {
        // const hours = Math.floor(decimalTime);
        // const minutes = Math.round((decimalTime - hours) * 60);
        const minutes = isHalfHour ? 30 : 0;
        return `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    return (
        <Modal
            open={modalIsOpen}
            centered={true}
            // width={550}
            footer={[]}
            onCancel={() => modalIsOpenHandler(false)}>

            <section className="">
                {!selectedTimeSlot ? (
                    <div className="column-container-wrapper" style={{ marginTop: 40 }}>
                        <div
                            className="col-full display-flex flex-vertical-center gap-s note-card bg-secondary padding-s radius-m">
                            <XCircle size={20} className="white-color" />
                            <p className="content-description white-color phone-m-width-semifull">
                                <FormattedMessage id="no-booking-date" />
                            </p>
                        </div>
                    </div>

                ) : (
                    <div className="column-container-wrapper bg-white padding-m radius-m">
                        <span id="close-huur-popup" className="close-popup-btn cursor-pointer default-color"><i
                            className="fa fa-close default-color"></i></span>
                        <h3 className="content-title size-body weight-semibold default-color margin-b-0">
                            <FormattedMessage id="choose-time-of-day" />
                        </h3>

                        {times.length === 0 && (
                            <Alert
                                showIcon
                                type="warning"
                                className="my-3"
                                message={<FormattedMessage id="period-not-available" />} />
                        )}

                        {/* Time list */}
                        <ul className="list-style-none" id="datum__popup-huur-list">
                            {times.map((time, index) => (
                                <li key={index}
                                    onClick={e => { setSelectedTime(time); modalIsOpenHandler(false); }}
                                    className={`border-bottom-solid padding-tb-s padding-lr-xs radius-bottom-s cursor-pointer hover-bg-grey hover-border-primary ${selectedTime === time && `selected-bg-primary selected-white-color selected-item`}`}
                                    // selected-bg-primary selected-white-color selected-item
                                    data-huur_val={time}>{time}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>

        </Modal>
    );
};

export default SloopSelectorTimeModal;
